#oauth-page-main {
    padding-top: 15vh;

    #centered-box {
        text-align: center;
    }
    #custom-content {
        margin-top: 5vh;
        margin-bottom: 5vh;
    }
    #discord-avatar {
        width: 100px;
        height: 100px;
        border-radius: 50px;
    }
}