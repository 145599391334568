@import "../../shared.scss";

#auth-page-main {
    #parent {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        #login-box {
            border-radius: 20px;
            position: relative;
            text-align: center;
            padding: 50px;
            width: 320px;
        }
    }
}