@import "../../shared.scss";

#landing-page-main {
    min-height: 100vh;
    text-align: center;
    font-family: "Poppins", "Muli", sans-serif;
    #landscape {
        background-size: cover;
        background-attachment: fixed;
        background-blend-mode: darken;
        background-color: #000000BB;
    }
    #logo {
        margin-top: 7vh;
        width: 40%;
        margin-bottom: 4vh;
        @include responsive(){
            width: 90%;
        }
    }
    h1 {
        font-family: "Dela Gothic One", "Muli", sans-serif;
    }
    h2 {
        color: #FFAA00;
    }
    #summary {
        margin: 9vh 0px;
    }
    #video-embed {
        width: 40vw;
        height: calc(40vw / 1.77);
        border-radius: 30px;
        margin-bottom: 9vh;
        @include responsive(){
            width: 90vw;
            height: calc(90vw / 1.77);
        }
    }
    #characters-text {
        margin: 12vh 0px;
    }
    #characters {
        display: grid;
        width: 100%;
        grid-template-columns: 1fr 1fr 1fr;
        @include responsive(){
            grid-template-columns: 1fr;
        }
        & > div {
            padding: 75px 30px;
            img {
                width: 40%;
                margin-bottom: 25px;
                @include responsive(){
                    margin-bottom: 10px;
                }
            }
            h3 {
                font-size: 30px;
                font-family: "Dela Gothic One", "Muli", sans-serif;
            }
            p {
                font-size: 18px;
            }
            @include responsive(){
                padding: 25px 10vw;
            }
        }
        #vacuum-info {
            background-color: #FFAA00;
        }
        #bunny-info {
            background-color: #FEF5FF;
            color: #000000;
        }
        #robot-info {
            background-color: #3C3C5E;
        }
    }
    #download-text {
        margin: 7vh 0px;
    }
    #community-links-text {
        margin: 7vh 0px 3vh 0px;
    }
    #footer {
        background-color: #000000AA;
        padding: 15px;
        margin-top: 50px;
    }
}

