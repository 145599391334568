@import "./shared.scss";

$NAVBAR_HEIGHT: 5vh;

html {
    scroll-behavior: smooth;
}

h1 {
    font-size: 30px;
}

h1, h2, h3, h4, h5, h6, p {
    margin: 0;
}

p, li {
    font-size: 15px;
}

a.unstyled {
    text-decoration: none;
    color: inherit;
}

body {
    height: 100vh;
    width: 100vw;
    overflow: hidden;
    margin: 0;
    font-family: $SANS_SERIF;
    background-color: $BG_PURPLE;
}

#root {
    height: 100vh;
    width: 100vw;
    overflow-y: auto;
    overflow-x: hidden;
}

.main-content {
    margin: none;
    padding: 50px 25vw;
    min-height: 100%;
    box-sizing: border-box;
}

button.custom {
	font-family: $SANS_SERIF;
    border: none;
	background-color: $INTERACTABLE_DEFAULT;
	color: #FFFFFF;
    font-weight: bold;
	border-radius: 10em;
	font-size: 19px;
	padding: 14px 18px;
    transition: background-color .3s, color .3s;

    &.small {
        font-size: 15px;
        padding: 10px 13px;
    }
	&.jumbo {
        font-size: 25px;
        padding: 18px 24px;
	}
	&:hover {
        background-color: $INTERACTABLE_HIGHLIGHTED;
	}
	&:active {
        background-color: $INTERACTABLE_SELECTED;
	}
	&.disabled {
        background-color: $INTERACTABLE_DISABLED;
        color: #FFFFFF80;
	}
}

button:focus, input:focus {
    outline: none;
}

input.custom, textarea.custom {
    font-family: $SANS_SERIF;
    border: none;
    box-sizing: border-box;
    padding: 13px;
    font-size: 19px;
    border-radius: 14px;
    background-color: $INTERACTABLE_DEFAULT;
    transition: background-color .3s;
    color: #FFFFFF;
    caret-color: #FFFFFF;
    &::placeholder{
        color: #FFFFFF80;
    }
    &:hover {
        background-color: $INTERACTABLE_HIGHLIGHTED;
	}
	&:focus {
        background-color: $INTERACTABLE_SELECTED;
	}
	&.disabled {
        background-color: $INTERACTABLE_DISABLED;
	}
    &.small {
        font-size: 15px;
        padding: 10px;
        border-radius: 10px;
    }
    &.jumbo {
        font-size: 25px;
        padding: 17px;
    }
}
textarea.custom {
    resize: vertical;
}

.shade {
    width: 100%;
    height: 100%;
    background-color: #00000080;
    z-index: 10;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 50px;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    border-radius: inherit;
}

.loader {
    width: 60px;
}

.message {
    border-radius: 14px;
    color: #FFFFFF;
    padding: 15px;
    max-width: 100%;
    text-align: left;
    p {
        margin: 0;
        font-size: 20px;
    }
    &.info {
        background-color: #7777FF80;
    }
    &.negative {
        background-color: #FF777780;
    }
    &.positive {
        background-color: #b3ffb380;
    }
    &.small {
        p { font-size: 15px; }
        padding: 11px;
        border-radius: 10px;
    }
    &.jumbo {
        p { font-size: 25px; }
        padding: 19px;
    }
}

.title {
    display: inline-block;
    text-align: center;
    &.with-icon {
        svg {
            font-size: 50px;
        }
    }
    h1 {
        font-weight: bold;
        font-size: 32px;
        margin-top: 10px;
    }
}

.stripesbg {
    background-attachment: fixed;
    background-size: 72px;
    background-repeat: repeat;
    background-image: url("./resources/purple_stripes.png");
    color: #FFFFFF;
}

#navbar {
    position: sticky;
    top: 0;
    color: #FFFFFF;
    height: $NAVBAR_HEIGHT;
    background-color: $BG_PURPLE;
    padding: 0 5px;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    #left-content, #right-content {
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .navbar-button {
        height: 100%;
        color: inherit;
        text-decoration: inherit;
        cursor: pointer;
        div {
            box-sizing: border-box;
            padding: 0 15px;
            margin: 0 10px;
            height: 100%;
            display: flex;
            align-items: center;
            flex-direction: row;
            justify-content: center;
            transition: background-color .2s;
            &:hover {
                background-color: adjust-color($BG_PURPLE, $lightness: +10%);
            }
        }
        p {
            text-transform: uppercase;
            font-weight: bold;
            font-size: 15px;
            white-space: nowrap;
            @include responsive(){
                font-size: 13px;
            }
        }
        img {
            max-height: 100%;
            max-width: 100%;
        }
    }
}

.name-badge {
    border-radius: 40px;
    background-color: $TRANSLUCENT_BLUE;
    font-size: 30px;
    padding: 15px 45px;
    display: inline-block;
    min-width: 15vw;
}

.modal {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #00000080;
    align-items: center;
    justify-content: center;
    &.shown {
        display: flex;
    }
    .modal-content {
        width: 50%;
        padding: 50px;
        text-align: center;
        h1, h2, h3, h4, h5, h6 {
            margin-bottom: 30px;
        }
        .modal-footer {
            margin-top: 50px;
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            button {
                margin-left: 10px;
            }
        }
    }
}

.download-buttons {
    
}