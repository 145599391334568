@import url('https://fonts.googleapis.com/css2?family=Mulish:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Bowlby+One&family=Bowlby+One+SC&family=Dela+Gothic+One&family=Poppins:ital,wght@0,400;0,600;0,700;1,400;1,600;1,700&display=swap');
$SANS_SERIF: "Mulish", sans-serif;
$HIGHLIGHT_GREEN: #72FFA4;
$HIGHLIGHT_BLUE: #51A3FF;
$INTERACTABLE_DEFAULT: #2C3B6F80;
$INTERACTABLE_HIGHLIGHTED: #4C5989AA;
$INTERACTABLE_SELECTED: #243059AA;
$INTERACTABLE_DISABLED: #2c2e37aa;
$TRANSLUCENT_BLUE: #2C3B6FBC;
$BG_PURPLE: #140224;

$MOBILE_WIDTH: 1000px;
@mixin responsive() {
    @media ( max-width: $MOBILE_WIDTH ) {
        @content;
    }
}