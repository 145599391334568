#forgot-password-main {
    .divider {
        margin-top: 30px;
    }
    
    #success-message {
        color: #00FF00;
    }
    
    #btn {
        margin-left: 15px;
    }
}
