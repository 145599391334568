#user-review-page-main {
    #main-title {
        margin-bottom: 50px;
    }
    
    .name-badge {
        margin-top: 50px;
        margin-bottom: 15px;
    }
    
    #split-pane {
        margin-top: 50px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: top;
        h2 {
            margin-bottom: 15px;
        }
        p {
            margin: 5px 0;
        }
        #mod-history {
            padding: 10px;
            width: 45%;
        }
        #divider {
            width: 0px;
            border-left: 1px #FFFFFF solid;
        }
        #username-history {
            padding: 10px;
            width: 45%;
        }
    }
    
    p.negative {
        color: red;
    }
    
    .ban-entry, .username-entry {
        margin: 15px 0px;
        border-top: 1px #FFFFFF solid;
        padding: 7px 0px;
    }
    
    #username-change {
        margin-bottom: 30px;
        button {
            margin-left: 15px;
        }
    }
    
    #account-id {
        margin-top: 10px;
        color: #FFFFFFAA;
    }
    
    #ban-user {
        margin-bottom: 30px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
    }
}