@import "../../shared.scss";

#profile-page-main {
    .name-badge {
        margin-top: 50px;
        margin-bottom: 15px;
    }
    
    #stats-table {
        margin-top: 50px;
        border-spacing: 10px 25px;
        padding: 5px 30px;
        background-color: transparentize($HIGHLIGHT_GREEN, $amount: .8);
        border-radius: 25px;
        tr > th:first-of-type {
            text-align: left;
        }
        th {
            font-size: 20px;
            font-weight: bold;
        }
        td {
            font-size: 17px;
        }
        width: 100%;
        img {
            width: 75px;
        }
        .col-total {
            font-size: 25px;
        }
        #games-played {
            color: $HIGHLIGHT_GREEN;
        }
        #table-header {
            text-align: center;
            font-size: 30px;
            color: $HIGHLIGHT_GREEN;
        }
    }
}
