#game-review-page-main {
    margin: none;
    padding: 50px 10vw;
    
    #main-title {
        margin-bottom: 50px;
    }
    
    #split-pane {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        column-gap: 3vw;
        margin-top: 50px;
        & > div {
            background-color: #00000055;
            border-radius: 15px;
        }
    }
    
    p {
        margin: 5px 0;
    }
    
    p.negative, span.negative {
        color: red;
    }
    
    .team-icon {
        height: 1rem;
        margin-right: 10px;
    }
    
    #player-list {
        .team-info {
            text-align: left;
            svg {
                margin-left: 10px;
            }
        }
    }
    
    #objective-list {
        text-align: left;
    }
    
    .flag-entry {
        margin: 15px 0px;
        border-top: 1px #FFFFFF solid;
        padding: 7px 0px;
        code {
            margin-left: 10px;
        }
        .team-icon {
            margin-right: 0;
            margin-left: 10px;
        }
    }
    
    .chat-entry {
        text-align: left;
    }
}
