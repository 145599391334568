@import "../../shared.scss";

#settings-page-main {
    h2 {
        color: $HIGHLIGHT_GREEN;
        margin-top: 40px;
        margin-bottom: 20px;
    }
    
    h3 {
        margin-bottom: 10px;
    }
    
    .flair-selection {
        border: 5px solid #FFFFFF00;
        border-radius: 10px;
        height: 50px;
        width: 50px;
        display: inline-block;
        margin-right: 10px;
        margin-top: 10px;
        transition: border-color .2s;
        &:hover {
            border-color: #FFFFFF80;
        }
        &.selected {
            border-color: #FFFFFFFF;
        }
    }
}
